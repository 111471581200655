import { graphql } from "gatsby";
import * as React from "react";
import { WmkSeo } from "wmk-seo";
import Layout from "../components/layout/MainLayout";
import { MetaDataQuery } from "../fragments/NodeSiteMetaFields";
import GradientAngleHeader from "../components/layout/GradientAngleHeader";
import { useState } from "react";
import { PageData } from "./page";
import { Page } from "../classes/Page";
import AllBlogs from "../components/Blog/AllBlogs";

const TagNode = ({
  data,
  pageContext
}: {
  data: {
    page: PageData;
    site: MetaDataQuery;
  };
  pageContext: { slug: string; tag: string };
}) => {
  const [innerHeight, setInnerHeight] = useState();
  const page = new Page(data.page);
  const { slug, tag } = pageContext;
  const metaData = data.site.meta;
  return (
    <Layout>
      <WmkSeo.Meta
        title={`${tag} Blogs`}
        path="/blog/tags/"
        slug={slug}
        description={"Blogs tagged: " + tag}
        ogImageUrl={null}
        twitterImageUrl={null}
        siteTitle={metaData.title}
        baseUrl={metaData.baseUrl}
        twitterHandle={
          "twitterHandle" in metaData ? metaData.twitterHandle : null
        }
      />
      <GradientAngleHeader
        height={innerHeight}
        setter={setInnerHeight}
        white
        title={page.title}
        text={page.header?.text}
      />
      <AllBlogs slug={slug} />
    </Layout>
  );
};

export default TagNode;

export const query = graphql`
  query tagQuery {
    page: contentfulPage(slug: { eq: "blog" }) {
      ...NodePageFields
    }
    site {
      ...NodeSiteMetaFields
    }
  }
`;
